import { configureStore, Store, combineReducers } from '@reduxjs/toolkit';
import superagentInterface from 'redux-query-interface-superagent';
import {
  queryMiddleware,
  QueriesState,
  entitiesReducer,
  queriesReducer,
} from 'redux-query';
import { Entities, State } from '../common/state';

export const getQueries = (state: State): QueriesState => state.queries;
export const getEntities = (state: State): Entities => state.entities;

const preloadedState = {};
const reducer = combineReducers<State>({
  entities: entitiesReducer,
  queries: queriesReducer,
});

const store: Store<State> = configureStore<State>({
  preloadedState,
  reducer,
  middleware: [queryMiddleware(superagentInterface, getQueries, getEntities)],
});

export default store;
