import { createMuiTheme } from '@material-ui/core';

export const light = {
  background: '#e3f2fd',
  palette: {
    type: 'light',
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
};

export default createMuiTheme(light as any);
