import { createMuiTheme, ThemeOptions } from '@material-ui/core';

const darkMUI: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#03a9f4',
    },
    secondary: {
      main: '#c0c2c4',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};

export const dark = {
  background: '#424242',
  ...darkMUI,
};

export default createMuiTheme(dark as any);
