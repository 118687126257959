import { createMuiTheme } from '@material-ui/core';

export const dark = {
  palette: {
    primary: {
      main: '#2196f3',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
};

export default createMuiTheme(dark as any);
