import * as React from 'react';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { StripeProvider } from 'react-stripe-elements';
import store, { getQueries } from './App.config';
import { asyncLoad, useQueryParam } from '../common/helpers';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MThemeProvider } from '@material-ui/core/styles';
import { THEMES } from '../common/themes';
import { CssBaseline } from '@material-ui/core';

const Payments = asyncLoad(import('../payments'));

/**
 * App will initialize all Providers and set things up
 */
export const App: React.FC = () => {
  const query = useQueryParam();
  const theme: 'dark' | 'light' = (query.get('theme') as any) || 'dark';

  return (
    <Provider store={store}>
      <ReduxQueryProvider queriesSelector={getQueries}>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY || ''}>
          <ThemeProvider theme={THEMES[theme]}>
            <MThemeProvider theme={THEMES[theme]}>
              <CssBaseline />
              <Payments />
            </MThemeProvider>
          </ThemeProvider>
        </StripeProvider>
      </ReduxQueryProvider>
    </Provider>
  );
};

export default App;
