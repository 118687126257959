import * as React from 'react';
import Loadable from 'react-loadable';

const Loading: React.FC = () => <div>loading...</div>;

/**
 * Code Splitting
 * Importing components asynchronously
 * https://reactjs.org/docs/code-splitting.html
 */

export const asyncLoad = (component: Promise<any>) =>
  Loadable({
    delay: 0,
    loading: Loading,
    loader: () => component,
  });
